@use '@angular/material' as mat;
@use 'node_modules/@easi-sis/core/styles/fontawesome/css/all';
// Import the SIS UI Lib base styles

@use 'spacer' as v;
@use 'ats_overwrites';

@use 'node_modules/@easi-sis/core/styles/sis-base' as ut with (
  $spacer: 1rem,
  $spacers: (
        0: 0,
        1: v.$ut-spacer-1, // 5px
        2: v.$ut-spacer-2, // 10px
        3: v.$ut-spacer-3, // 15 px
        4: v.$ut-spacer-4, // 20px
        5: v.$ut-spacer-5, // 40px
        6: v.$ut-spacer-6  // 80px
  )
);

$popover-max-width: 340px;

  /* You can add global styles to this file, and also import other style files */
//@import "node_modules/bootstrap/scss/popover";

// for font-awesome (version 5)
@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";   // example icon:  class="fas fa-paperclip"
@import "node_modules/@fortawesome/fontawesome-free/scss/regular"; // example icon:  class="far fa-check-circle"

// cause the trailing button on a field like a date field line up with the text widget
.input-group .btn {
  //height: $input-height; $input-height: 1.6rem !default;
  height: 4rem;
}

// on the top nav fix the selection box overhanging the width of the nav menu
.nav-item .nav-link.active {
      outline-offset: 0;
}

// material table changes


.mat-cell {
  padding-left: v.$ut-spacer-2 !important;
  padding-right: v.$ut-spacer-2 !important;

  padding-top: v.$ut-spacer-1 !important;
  padding-bottom: v.$ut-spacer-1 !important;

  min-width: v.$ut-spacer-2 !important;

  border-bottom: none !important;
}

.mat-table-sticky {
  //background-color: var(--ut-colour-table-fill);
  background-color: var(--ut-base-colour-pale-grey)
}

.mat-table-sticky:first-child {
  border-top: 1px solid var(--ut-colour-swatch-light-grey);
  border-left: 1px solid var(--ut-colour-swatch-light-grey);
  border-right: 2px solid var(--ut-colour-swatch-light-grey);
  text-align: left;
}

.mat-row {
  white-space: nowrap;
  text-align: left;
  height: auto !important;
  padding-top: v.$ut-spacer-1;
  padding-bottom: v.$ut-spacer-1;
}

.mat-row:hover{
  cursor: pointer;
  background: #D9EDF7;
}

.table-container th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: v.$ut-spacer-1;
}

.mat-header-cell {
  padding-left: v.$ut-spacer-2 !important;
  padding-right: v.$ut-spacer-2 !important;
  text-align: left;
  border-top: 1px solid var(--ut-colour-swatch-light-grey);
  border-bottom: none !important;
}

.mat-sort-header-content {
  text-align: left !important;
}

.table-container {
  overflow-x: scroll;
  line-height: 1.1;
}

  th.mat-column-position,
  td.mat-column-position {
    padding-left: v.$ut-spacer-2 !important;
    padding-right: v.$ut-spacer-2 !important;
    border-bottom: 1px solid var(--ut-colour-swatch-light-grey) !important;
  }

  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type {
    padding-top: 0 ;
    padding-bottom: 0;
    padding-left: v.$ut-spacer-1;
    padding-right: v.$ut-spacer-1 !important;
  }

$ats-mat-table-header-font-size: 1.8rem;

$custom-typography: mat.define-legacy-typography-config(
    $font-family: ut.$typography-font-stack,
    $body-2: mat.define-typography-level(ut.$typography-small-font-size, 1.1, ut.$typography-weight-bold),
    $body-1: mat.define-typography-level(ut.$typography-body-font-size, 1.1, ut.$typography-weight-regular),
    $caption: mat.define-typography-level($ats-mat-table-header-font-size, 1.1, ut.$typography-weight-bold),
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
//@include mat.all-legacy-component-typographies($custom-typography);
//@include mat.legacy-core();



.margin-top-spacer-4 {
  margin-top: v.$ut-spacer-4;
}

.margin-top-30-px {
  margin-top: 30px;
}

.margin-top-40-px {
  margin-top: 40px;
}

.margin-top-80-px {
  margin-top: 80px;
}

.margin-top-25-px {
  margin-top: 25px;
}

.margin-top-15-px {
  margin-top: 15px;
}

.margin-top-4-rem {
  margin-top: 4rem;
}

.margin-top-2-rem {
  margin-top: 2rem;
}

.margin-top-1-rem {
  margin-top: 1rem;
}


.margin-bottom-0 {
  margin-bottom: 0!important;
}

.margin-bottom-2-rem {
  margin-bottom: 2rem;
}

.margin-left-80px {
  margin-left: 80px;
}

.margin-left-1rem {
  margin-left: 1rem;
}

.margin-left-2rem {
  margin-left: 2rem;
}

.margin-left-4rem {
  margin-left: 4rem;
}

.margin-left-6rem {
  margin-left: 6rem;
}

.margin-left-8rem {
  margin-left: 8rem;
}

.margin-right-2rem {
  margin-right: 2rem;
}

.margin-right-4rem {
  margin-right: 4rem;
}

.pl-30px {
  padding-left: 30px;
}

.page-head {
  margin-right: v.$ut-spacer-5;
  display: flex;
  justify-content: space-between;
}

.left-border-zero-radius {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.right-border-zero-radius {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.underline-dotted {
  text-decoration-style: dotted;
  text-decoration-line: underline;
}

.underline {
  text-decoration-line: underline;
}

.text-wrapper {
  white-space: normal;
}

.font-size-16px {
  font-size: 16px;
}

.non-bold {
  font-weight: normal !important;
}

.bold {
  font-weight: bold !important;
}

.reset-button {
  border: 1px solid #0054BA!important;
}

