
ngb-timepicker .btn {
  border: 0;
}

// Overwrite
.ngb-tp-input-container {
  width: 5em !important;
}

